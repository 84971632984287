import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import serviceBanner from "../images/grid-maintenance.jpg";
import telcoTower from "../images/telco-tower-dark.jpeg";
import radioNetwork from "../images/network.jpg";
import projectManagement from "../images/projectManagement.jpg";
import electricPower from "../images/electricPower.jpg";

export default function Services() {
  let yearsStarted = new Date().getFullYear() - 2015;
  return (
    <Layout>
      <SEO title="Services" />
      <div
        className="bg-cover bg-center h-screen flex items-end pb-12"
        style={{
          backgroundImage: `linear-gradient(
          to right,
          rgba(9, 32, 111, 0.6),
          rgba(6, 18, 123, 0.7)
        ),url(${serviceBanner})`,
        }}
      >
        <div className="text-white max-w-xl mx-auto">
          <div className="py-20 text-center">
            <h3 className="text-3xl font-extrabold tracking-widest">
              Our Services
            </h3>
            <p className="text-xl font-medium py-5">
              Enable Innovation with Network Expansion
            </p>
            <p className="font-medium py-5">
              We focus on developing pragmatic strategies to address our
              clients' most critical challenges.
            </p>

            {/* <p className="transform font-extrabold text-6xl">
              <span className="border rounded-full px-5 pb-5">
              &#8609;
              </span>
              </p> */}
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-6 xl:px-0">
        <div className="flex flex-wrap my-20 ">
          <div className="w-full lg:w-1/5 py-5 lg:py-0 lg:border-r-8 border-blue-800">
            <h3 className="font-bold text-3xl capitalize">
              {yearsStarted} yrs of immaculate service
            </h3>
          </div>
          <div className="w-full lg:w-4/5 lg:pl-10">
            <p>
              It's been {yearsStarted} years since we started CM Teleservices
              and have delivered nothing but the best performance in all of our
              ventures to date. We have trained ourselves, overcome adversities,
              achieved and maintained a high level of expertise, and gathered
              the most competent team of professionals to carry out all forms of
              technical services for all of our clients.
            </p>
            <p className="py-2">
              Maintaining an amicable and healthy professional relationship with
              all of our clients and partners is of utmost importance to Cm
              Teleservices. May it be {yearsStarted} or 50, CM Teleservices
              promise to serve clients to the best in all of the years to come.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap my-20 items-center">
          <div className="w-full lg:w-1/2">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Network Growth
            </h3>
            <img src={telcoTower} alt="telecom tower" />
          </div>
          <div className="w-full lg:w-1/2 lg:pl-20">
            <h3 className="text-3xl font-bold capitalize mb-5 hidden lg:block">
              Network Growth
            </h3>
            <p className="py-2 lg:py-0">
              Enough coverage and congestion less network is in demand to meet
              the satisfaction level of customers. CM Teleservices has always
              been a trusted partner to provide pre-construction services like
              site survey and acquisition. Our team of skilled professionals
              leverage their telecom experience to provide construction,
              installation and commissioning services to our clients. The
              dedicated teams at CM Teleservices have in-depth knowledge of
              wireline and wireless networks; fiber, broadband, satellite and
              cable operations. Our professionals come from within the industry
              and sustain thorough understanding of the strategic, operational,
              technological, financial and customer acquisition and retention
              issues commonly faced by telecom providers.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap my-20 items-center">
          <div className="w-full lg:w-1/2 lg:hidden">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Network Planning and optimization
            </h3>
            <img src={radioNetwork} alt="telecom networking" />
          </div>
          <div className="w-full lg:w-1/2 lg:pr-20">
            <h3 className="text-3xl font-bold capitalize mb-5 hidden lg:block">
              Network Planning and optimization
            </h3>
            <p className="py-2 lg:py-0">
              Telecommunication network operators are on a constant challenge to
              provide new services which require good network coverage and
              guaranteed quality of service. Network planning is an extremely
              important process involving topological design, network synthesis,
              network realization and is aimed at ensuring that a new network or
              service meets the need of both the subscriber as well as the
              operator. CM Teleservices uses best tools and techniques available
              to provide services like load balancing, minimize latency, packet
              loss monitoring and bandwidth management. CM Teleservices also
              provides transmission network planning for better and stable
              network.
            </p>
          </div>
          <div className="w-full lg:w-1/2 hidden lg:block">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Network Planning and optimization
            </h3>
            <img src={radioNetwork} alt="telecom networking" />
          </div>
        </div>
        <div className="flex flex-wrap my-20 items-center">
          <div className="w-full lg:w-1/2">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Project Management
            </h3>
            <img src={projectManagement} alt="Project Management" />
          </div>
          <div className="w-full lg:w-1/2 lg:pl-20">
            <h3 className="text-3xl font-bold capitalize mb-5 hidden lg:block">
              Project Management
            </h3>
            <p className="py-2 lg:py-0">
              In addition to providing various planning and implementation
              services, CM Teleservices highly capable of managing all
              telecommunication projects. CM Teleservices can provide full
              turnkey services including project planning, implementation and
              monitoring daily progress of work on site. CM Teleservices is also
              capable of quality control, procurement and vendor management
              services.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap my-20 items-center">
          <div className="w-full lg:w-1/2 lg:hidden">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Energy/Power Solutions
            </h3>
            <img src={electricPower} alt="Working on Electricity Grid" />
          </div>
          <div className="w-full lg:w-1/2 lg:pr-20">
            <h3 className="text-3xl font-bold capitalize mb-5 hidden lg:block">
              Energy/Power Solutions
            </h3>
            <p className="py-2 lg:py-0">
              Effective power grid evaluation deals with the effective
              evaluation of the electric power grid and explores the role that
              this process plays in the planning and designing of the expansion
              of the power grid. It is always challenging to keep today's
              complex power systems and grids up and running efficiently. CM
              Teleservices can provide operation and maintenance of grids and
              substations maintaining the reliability required for successful
              operation.
            </p>
            <p>
              Solar system has been supplement or provide an alternative to
              conventional energy sources providing reliable solutions to the
              rural communities. CM Teleservices can provide services in full
              turnkey solar energy system.
            </p>
            <p>
              CM Teleservices, active in the telecommunications industry, has
              recognized expertise and very robust capacities in applications
              related to the telecommunications industry. So, CM Teleservices
              can provide custom energy solutions for telecommunications
              industry.
            </p>
          </div>
          <div className="w-full lg:w-1/2 hidden lg:block">
            <h3 className="text-3xl font-bold capitalize mb-5 lg:hidden">
              Energy/Power Solutions
            </h3>
            <img src={electricPower} alt="Working on Electricity Grid" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
